/**
 * IE対策
 */
import './modules/ie11';

/**
* スマホ100vh対策
*/
import { ViewportModule } from './modules/viewport';
const $viewport = new ViewportModule.Service();

/**
 * wordpress plugin
 * MW WP Form
 */
import { MwWpFormModule } from './modules/mw_wp_form';
const $mwWpFormModule = new MwWpFormModule.Service();

/**
 * スムーズスクロール
 * 別ページからのアンカーリンク
 */
import { SmoothScrollModule } from './modules/smooth_scroll';
const $smoothScrollModule = new SmoothScrollModule.Service();

/**
 * スクロールイベント
 * 「.js__scroll」に対して処理をする
 */
import { ScrollEventModule } from './modules/scroll_event';
const $scrollEventModule = new ScrollEventModule.Service();

/**
 * toggle
 * 「.js__toggle」に対して処理をする
 */
import { ToggleActionModule } from './modules/toggle_action';
const $toggleActionModule = new ToggleActionModule.Service();

/**
 * DatePicker
 * 「input.calendar | .inputWrap--calendar input」に対して処理をする
 */
import { DatePickerModule } from './modules/datePicker';
const $datePickerModule = new DatePickerModule.Service();

/**
 * 読み込み完了
 */
import { LoadModule } from './modules/load';
const $load = new LoadModule.Service();
