export module MwWpFormModule {
  export class Service {
    constructor(language?: string) {
      document.addEventListener('readCompleteAction', function () {
        const $mw_wp_form = document.getElementsByClassName('mw_wp_form');
        if ($mw_wp_form && $mw_wp_form.length) {
          for (let $i = 0; $i < $mw_wp_form.length; $i++) {
            //alert('MwWpFormModule');
            const $form = $mw_wp_form[$i];
            $form.setAttribute('action', '#' + $form.getAttribute('id'));
          }
        }

        const $mw_wp_form_input = document.querySelector<HTMLElement>('.mw_wp_form_input');
        if ($mw_wp_form_input) {
          const $errors = $mw_wp_form_input.querySelector<HTMLElement>('.error');
          if ($errors) {
            let $offsetTop = window.pageYOffset + $mw_wp_form_input.getBoundingClientRect().top;
            let $adminBar = document.getElementById('wpadminbar');
            if ($adminBar) {
              $offsetTop -= $adminBar.offsetHeight;
            }
            window.scrollTo({
              top: $offsetTop,
            });
          }
        }

        const $mw_wp_form_policy = document.querySelector<HTMLElement>('input[type="checkbox"][value="個人情報保護方針を確認しました"]');
        if ($mw_wp_form_policy) {
          const $label = $mw_wp_form_policy.closest('label');
          if ($label) {
            const $text = $label.querySelector('.mwform-checkbox-field-text');
            if ($text) {
              $text.innerHTML = '<a href="/privacy/"><span>' + '個人情報保護方針' + '</span></a>を確認しました';
            }
          }
        }
      }, false);
    }
  }
}
