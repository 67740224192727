/**
 * IE11対策
 */

interface Element {
  msMatchesSelector(selectors: string): boolean;
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function ($sting: any) {
    var $element: any = this;

    do {
      if (Element.prototype.matches.call($element, $sting)) return $element;
      $element = $element.parentElement || $element.parentNode;
    } while ($element !== null && $element.nodeType === 1);
    return null;
  };
}

if (navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
  document.body.addEventListener("mousewheel", function ($event: any) {
    $event.preventDefault();
    var wd = $event.wheelDelta;
    var csp = window.pageYOffset;
    window.scrollTo(0, csp - wd);
  });
}