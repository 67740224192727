/**
 * デフォルトのモジュールを読込
 */
import './Default';

/**
 * mein header
 */
import { MainHeaderModule } from './components/organisms/mainHeader';
new MainHeaderModule.Service();
