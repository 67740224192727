import { Ease } from './ease';
import { Offset } from './offset';

export module SmoothScrollModule {
  export class Service {
    public static SPEED: number = 500;
    private static EASE = Ease.EaseFunction;

    constructor(language?: string) {
      // 別ページからのアンカーリンク
      document.addEventListener('loadCompleteAction', () => {
        Service.otherPageScroll();
      });

      // スムーズスクロール
      document.addEventListener('readCompleteAction', () => {
        Service.smoothScroll();
      });

      // no scroll
      document.addEventListener('readCompleteAction', () => {
        Service.noScroll();
      });
    }

    /**
     * 別ページからのアンカーリンク
     */
    public static otherPageScroll() {
      let $urlHash = location.hash;
      if ($urlHash.replace('#', '')) {
        let $targetElement = document.getElementById($urlHash.replace('#', ''));
        if ($urlHash && $targetElement) {
          let $offsetTop = window.pageYOffset + $targetElement.getBoundingClientRect().top;
          let $adminBar = document.getElementById('wpadminbar');
          if ($adminBar) {
            $offsetTop -= $adminBar.offsetHeight;
          }
          window.scrollTo({
            top: $offsetTop,
          });
        }
      }
    }

    /**
     * スムーズスクロール
     */
    public static smoothScroll() {
      let $ankers = document.querySelectorAll('a[href^="#"]:not(.noscroll)');
      if ($ankers && $ankers.length) {
        $ankers.forEach(($anker) => {
          $anker.addEventListener('click', ($event) => {
            let $href: string | null = $anker.getAttribute('href');
            let $currentPostion: number = Service.bodyOrHtml().scrollTop;
            let $targetElement: HTMLElement | null;
            let $targetPosition: number;
            let $startTime: number;

            if ($href) {
              $targetElement = document.getElementById($href.replace('#', ''));
              if ($targetElement || '#' === $href) {
                $event.preventDefault();

                $startTime = performance.now();
                $targetPosition = ('#' === $href || null === $targetElement) ? 0 : Offset.getTop($targetElement);

                let loopFunction = function ($nowTime: number) {
                  let $time = $nowTime - $startTime;
                  let $normalizedTime = $time / Service.SPEED;
                  if ($normalizedTime < 1) {
                    window.scrollTo(0, $currentPostion + (($targetPosition - $currentPostion) * Service.EASE.easeInOut($normalizedTime)));
                    requestAnimationFrame(loopFunction);
                  } else {
                    window.scrollTo(0, $targetPosition);
                  }
                }

                requestAnimationFrame(loopFunction);
              }

              return false;
            }
          });
        });
      }
    }

    private static bodyOrHtml() {
      if (navigator.userAgent.indexOf('WebKit') != -1) {
        return document.body;
      }
      return document.documentElement;
    }

    /**
     * no scroll
     */
    public static noScroll() {
      const $ankers = document.querySelectorAll('a.noscroll[href^="#"]');
      $ankers.forEach($anker => {
        $anker.addEventListener('click', ($event) => {
          $event.preventDefault();
          return false;
        });
      });
    }
  }
}
