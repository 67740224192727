import { functions } from "./functions";

export module LoadModule {
  export class Service {
    private static BODY: Element = document.getElementsByTagName('body')[0];

    constructor(language?: string) {
      document.addEventListener('DOMContentLoaded', function () {
        if (!Service.BODY.classList.contains('ready-complete')) {
          let $EVENT;
          try {
            $EVENT = new Event('readCompleteAction');
          } catch (e) {
            $EVENT = document.createEvent('Event');
            $EVENT.initEvent('readCompleteAction', false, true);
          }

          Service.BODY.classList.add('ready-complete');

          const $DEVICE = functions.getDevice();
          if ($DEVICE) {
            if ('pc' === $DEVICE) {
              Service.BODY.classList.add('isPc');
            } else if ('tablet' === $DEVICE) {
              Service.BODY.classList.add('isTablet');
              Service.BODY.classList.add('isSp');
            } else if ('sp' === $DEVICE) {
              Service.BODY.classList.add('isSp');
            }
          }

          if (functions.isTouchDevice()) {
            Service.BODY.classList.add('isTouchDevice');
          } else {
            Service.BODY.classList.add('isNotTouchDevice');
          }

          const $OS = functions.getOS();
          if ($OS) {
            Service.BODY.classList.add($OS);
          }

          const $BROWSER = functions.getBrowser();
          if ($BROWSER) {
            Service.BODY.classList.add($BROWSER);
          }

          document.dispatchEvent($EVENT);
        }
      }, false);

      window.addEventListener("load", function () {
        if (!Service.BODY.classList.contains('ready-complete')) {
          let $EVENT;
          try {
            $EVENT = new Event('readCompleteAction');
          } catch (e) {
            $EVENT = document.createEvent('Event');
            $EVENT.initEvent('readCompleteAction', false, true);
          }

          Service.BODY.classList.add('ready-complete');
          document.dispatchEvent($EVENT);
        }
        if (!Service.BODY.classList.contains('load-complete')) {
          let $EVENT;
          try {
            $EVENT = new Event('loadCompleteAction');
          } catch (e) {
            $EVENT = document.createEvent('Event');
            $EVENT.initEvent('loadCompleteAction', false, true);
          }

          Service.BODY.classList.add('load-complete');
          document.dispatchEvent($EVENT);
        }
      });
    }
  }
}
