/**
 * メインヘッダー
 */

export module MainHeaderModule {
  export class Service {
    constructor(language?: string) {
      document.addEventListener('readCompleteAction', () => {
        Service.setEvent();
      });
    }

    /**
     * メインヘッダー
     */
    public static setEvent() {
      $('.mainHeader__megaNav').on('click', '.headerMenuButton', () => {
        const $self = $('.mainHeader__megaNav .headerMenuButton');
        $self.toggleClass('headerMenuButton--isOpen');
      });

      const $menuItems = document.querySelectorAll('.mainHeader__nav .menu > .menu-item');
      $menuItems.forEach(($menuItem) => {
        $menuItem.addEventListener('mouseover', () => {
          const $self = $menuItem;
          const $parent = $menuItem.closest('.mainHeader__nav');

          if ($self.classList.contains('menu-item--isActive')) {
            return;
          }

          if ($parent && $parent.querySelectorAll('.menu-item--isActive')) {
            $parent.querySelectorAll('.menu-item--isActive').forEach($isActive => {
              $isActive.classList.remove('menu-item--isActive');
            });
          }

          $self.classList.add('menu-item--isActive');
        });
      });

      const $menus = document.querySelectorAll('.mainHeader__nav');
      $menus.forEach($menu => {
        $menu.addEventListener('mouseleave', () => {
          const $self = $menu;
          const $isActives = $self.querySelectorAll('.menu-item--isActive');
          $isActives.forEach($isActive => {
            $isActive.classList.remove('menu-item--isActive');
          });
        });
      });
    }
  }
}